// @flow strict
import React from 'react'
import { Box, Badge, Flex } from '@chakra-ui/core'
import styles from './Repo.module.scss'

type Props = {
  name: string,
  description: string,
  language: string,
  stars: number,
  url: string,
  logoUrl?: string,
}

const LANGUAGE_COLOR = {
  go: 'purple',
  python: 'green',
  typescript: 'blue',
}

const Repo = ({ name, description, language, stars, url, logoUrl }: Props) => (
  <a href={url} className={styles['github-link']}>
    <Flex
      borderRadius="lg"
      borderStyle="solid"
      borderWidth="1px"
      borderColor="grey"
      w="100%"
      p="3"
      direction="column"
      justify="space-between"
    >
      <Box>
        <Flex
          fontWeight="bold"
          fontSize="x-large"
          mb="3"
          justify="space-between"
        >
          <Box>{name}</Box>
          {logoUrl !== undefined ? (
            <Box>
              <img src={logoUrl} />
            </Box>
          ) : null}
        </Flex>
        <Box>{description}</Box>
      </Box>
      <Box>
        <hr className={styles['bar']} />
        <Flex justify="space-between" pt="0.8em">
          <Badge
            borderRadius="full"
            px="2"
            colorScheme={LANGUAGE_COLOR[language.toLowerCase()] || 'red'}
          >
            {language}
          </Badge>
          <Box>{stars} ★</Box>
        </Flex>
      </Box>
    </Flex>
  </a>
)

export default Repo
