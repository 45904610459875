// @flow strict
import React from 'react'
import { Box, Image, Heading, Grid } from '@chakra-ui/core'
import Layout from '../components/Layout'
import TopBar from '../components/TopBar'
import Page from '../components/Page'
import Repo from '../components/Repo'
import Project from '../components/Project'
import Screenshot from '../components/Screenshot'
import Footer from '../components/Footer'
import { useSiteMetadata, useGitHubRepos } from '../hooks'
import styles from './index-template.module.scss'

const IndexTemplate = () => {
  const { title: siteTitle, subtitle: siteSubtitle } = useSiteMetadata()
  const github = useGitHubRepos()

  return (
    <Layout title={`${siteTitle}`} description={siteSubtitle}>
      <TopBar />
      <Page full={true}>
        <section>
          <Heading mt="0">About me</Heading>
          <Box>
            <p>
              I carry products from the initial customer interview to the
              production deployment. I have a passion for automation and visual
              communication, usually through data visualization. I'm a security
              expert and a cloud and container guru, always up on the latest
              SecOps/DevOps techniques. I'm an open source maintainer and
              regular contributor, and I believe companies which embrace open
              and inner-source strategies have an advantage.
            </p>
            <p>
              My time is filled with passion projects. I'm a{' '}
              <a href="https://www.faa.gov/about/office_org/headquarters_offices/avs/offices/afx/afs/afs800/afs820/part107_oper/">
                Part 107 FAA-certified
              </a>{' '}
              commercial drone pilot, I take aerial photographs and design
              racing drones. I hack micro controllers to automate the boring
              parts of life. And I work the stock market through trading
              algorithms.
            </p>
            <p>
              Check out some of my projects below, and reach out if you'd like
              to collaborate!
            </p>
          </Box>
        </section>
        <section>
          <Heading>Projects and Specializations</Heading>
          <Project color="#474782">
            <div>
              <Heading as="h3" mt={0} fontSize="2xl">
                Data Analytics and Visualization
              </Heading>
              <ul>
                <li>
                  <a href="https://splunk.paloaltonetworks.com">
                    Most downloaded 3rd-party App for Splunk
                  </a>
                </li>
                <li>
                  <a href="https://www.linkedin.com/company/xseer/">
                    Startup XSeer acquired for data visualization at scale
                  </a>
                </li>
                <li>First San Jose VTA Lightrail App for iOS</li>
              </ul>
            </div>
            <Screenshot
              src="/media/prisma-access.png"
              alt="Prisma Access Visualizations"
            />
          </Project>
          <p></p>
          <Project color="rgb(21, 62, 117)">
            <div>
              <Heading as="h3" mt={0} fontSize="2xl">
                DevOps and SecOps Automation
              </Heading>
              <ul>
                <li>
                  <a href="https://www.cisco.com/c/en/us/td/docs/switches/datacenter/aci/apic/sw/4-x/L4-L7-services/Cisco-APIC-Layer-4-to-Layer-7-Services-Deployment-Guide-411/Cisco-APIC-Layer-4-to-Layer-7-Services-Deployment-Guide-401_chapter_0100.html#id_86600">
                    Cisco ACI Device Package for Palo Alto Networks
                  </a>
                </li>
                <li>
                  <a href="https://www.ansible.com/integrations/networks/palo-alto">
                    Ansible Modules for security automation
                  </a>
                </li>
                <li>
                  <a href="https://registry.terraform.io/namespaces/PaloAltoNetworks">
                    Terraform Provider and Modules for security and cloud
                    automation
                  </a>
                </li>
                <li>CI/CD Infrastructure for DevOps/SecOps</li>
                <li>
                  <a href="https://enterprise.github.com/releases/series/2.22">
                    GitHub Actions for Enterprise Server beta program
                    contributor
                  </a>
                </li>
              </ul>
            </div>
            <Screenshot
              src="/media/cisco-aci.png"
              alt="Cisco ACI Network Automation"
            />
          </Project>
          <p></p>
          <Project color="rgb(61, 132, 109)">
            <div>
              <Heading as="h3" mt={0} fontSize="2xl">
                Developer Advocacy
              </Heading>
              <ul>
                <li>
                  <a href="https://strata.pan.dev/docs/apis/panpython_qs">
                    Software Development Kits
                  </a>
                </li>
                <li>
                  <a href="https://strata.pan.dev/">
                    Developer Portal for PAN-OS
                  </a>
                </li>
                <li>
                  <a href="https://gallery.pan.dev/">Open Source Gallery</a>
                </li>
                <li>
                  <a href="#open-source">Maintainer of open source projects</a>
                </li>
                <li>
                  <a href="https://resources.github.com/whitepapers/introduction-to-innersource/">
                    Enterprise-wide Innersource Programs
                  </a>
                </li>
              </ul>
            </div>
            <Screenshot src="/media/dev-site.png" alt="Developer Portal" />
          </Project>
        </section>
        <section>
          <Heading id="open-source">Open Source Maintainer</Heading>
          <Box p="1em">
            As an open source maintainer, I'm responsible for developing a
            thriving community around these open source projects. It's important
            that each contribution is valued and each voice heard when running
            open source communities.
          </Box>
          <Grid
            templateColumns={{ md: '1fr 1fr' }}
            autoRows="1fr"
            gap="1.5em"
            w="100%"
            p="1em"
          >
            <Repo
              name="pan-os-python"
              description="The PAN-OS SDK for Python is a package to interact with Palo Alto Networks firewalls and Panorama with objects that mimics the GUI or CLI/API."
              stars={github.panOsPython.stargazers.totalCount}
              language={github.panOsPython.primaryLanguage.name}
              url="https://github.com/PaloAltoNetworks/pan-os-python"
            />
            <Repo
              name="pan-os-go"
              description="pango is the underlying library for the Palo Alto Networks Terraform provider"
              stars={github.panGo.stargazers.totalCount}
              language={github.panGo.primaryLanguage.name}
              url="https://github.com/PaloAltoNetworks/pango"
            />
            <Repo
              name="Splunk-Apps"
              description="pango is the underlying library for the Palo Alto Networks Terraform provider"
              stars={github.splunkApps.stargazers.totalCount}
              language={github.splunkApps.primaryLanguage.name}
              url="https://github.com/PaloAltoNetworks/Splunk-Apps"
            />
            <Repo
              name="ansible-pan"
              description="pango is the underlying library for the Palo Alto Networks Terraform provider"
              stars={github.ansiblePan.stargazers.totalCount}
              language={github.ansiblePan.primaryLanguage.name}
              url="https://github.com/PaloAltoNetworks/ansible-pan"
            />
            {/* <Repo
              name="pan-os-ansible"
              description=""
              stars={github.panOsAnsible.stargazers.totalCount}
              language={github.panOsAnsible.primaryLanguage.name}
              url="https://github.com/PaloAltoNetworks/pan-os-ansible"
            /> */}
            <Repo
              name="terraform-provider-panos"
              description="pango is the underlying library for the Palo Alto Networks Terraform provider"
              stars={github.terraformProvider.stargazers.totalCount}
              language={github.terraformProvider.primaryLanguage.name}
              url="https://github.com/terraform-providers/terraform-provider-panos"
            />
            <Repo
              name="passport-cortex"
              description="pango is the underlying library for the Palo Alto Networks Terraform provider"
              stars={github.passportCortex.stargazers.totalCount}
              language={github.passportCortex.primaryLanguage.name}
              url="https://github.com/PaloAltoNetworks/passport-cortex"
            />
          </Grid>
        </section>
        <Footer />
      </Page>
    </Layout>
  )
}

export default IndexTemplate
