// @flow strict
import React from 'react'
import styles from './Screenshot.module.scss'

type Props = {
  src: string,
  alt: string,
}

const Screenshot = ({ src, alt }: Props) => (
  <a class={styles['screenshot-container']}>
    <div class={styles['screenshot']}>
      <img src={src} alt={alt} />
    </div>
  </a>
)

export default Screenshot
