// @flow strict
import React from 'react'
import type { Node as ReactNode } from 'react'
import styles from './Project.module.scss'

type Props = {
  color: string,
  children: ReactNode,
}

const Project = ({ color, children }: Props) => (
  <div class={styles['project']} style={{ backgroundColor: color }}>
    {children}
  </div>
)

export default Project
