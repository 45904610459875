// @flow strict
import React from 'react'
import styles from './Footer.module.scss'

const Footer = () => (
  <div class={styles['footer']}>
    Created by Brian Torres-Gil using Gatsby and Netlify
  </div>
)

export default Footer
