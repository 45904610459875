// @flow strict
import React from 'react'
import Author from '../TopBar/Author'
import Contacts from '../TopBar/Contacts'
import styles from './TopBar.module.scss'
import { useSiteMetadata } from '../../hooks'

type Props = {
  isIndex?: boolean,
}

const TopBar = ({ isIndex }: Props) => {
  const { author } = useSiteMetadata()

  return (
    <div className={styles['topbar']}>
      <div className={styles['topbar__inner']}>
        <Author author={author} isIndex={isIndex} />
        <Contacts contacts={author.contacts} />
      </div>
    </div>
  )
}

export default TopBar
